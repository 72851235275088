import React from "react";
import "./info.styles.scss";

const Info = () => {
  return (
    <div className="info__main">
      <h1>What are we actually doing?</h1>
      <p className="info--1">
        <li>
          Join our community of talented individuals seeking guidance on
          navigating the real world and achieving their aspirations.
        </li>
        <li>
          Embrace a life without boundaries by stepping out of your comfort zone
          and pursuing your goals.
        </li>
        <li>
          Collaborate with like-minded individuals in a supportive environment
          to help each other reach new heights.
        </li>
        <li>
          Stay informed about the latest advancements and news in AI technology,
          ensuring you're always at the forefront.
        </li>

        <li>
          The possibilities are endless — join us on this journey of continuous
          growth and discovery.
        </li>
      </p>
    </div>
  );
};

export default Info;
