import React from "react";
import "./footer.styles.scss";
import Form from "./Form";

const Footer = () => {
  return (
    <div className="Footer__main">
      <div className="Footer__main--content">
        <h1>What can we do about this?</h1>
        <p>
          We're are a <span className="sp">group of engineers</span> trying to
          <span className="sp">
            find solutions to major problems and we've come up with quite a
            solution and present it to you in the form of a newsletter
          </span>
          so you donot have to spend all day on the web.{" "}
          <span className="sp">
            You can join it, it is 100% free as we are just starting out!
          </span>
        </p>
      </div>

      <div className="Footer__main--form">
        <h1>
          Join a community of people, Who think differently! & it is{" "}
          <strong>100% free</strong>
        </h1>
        <div className="bform">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Footer;
