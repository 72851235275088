import React from "react";
import "./offer.styles.scss";

const OfferCards = ({ title, p1, p2 }) => {
  return (
    <div className="offer--card">
      <div className="ccon card--con-1">
        <h1>{title}</h1>
      </div>
      <div className="ccon card--con-2">
        <p>{p1}</p>
      </div>
      <div className="ccon card--con-3">
        <p>{p2}</p>
      </div>
    </div>
  );
};

export default OfferCards;
