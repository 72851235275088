import React from "react";
import "./main.styles.scss";
import Slider from "./Slider";
import Loader from "./Loader";
const Main = () => {
  return (
    <div className="Mid_main">
      <h1>The Real Struggle.</h1>
      <div className="Mid_main__sub">
        <Slider />
      </div>
    </div>
  );
};

export default Main;
