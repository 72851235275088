import React from "react";
import "./header.styles.scss";
import Form from "./Form";
const Header = () => {
  return (
    <div className="header__main">
      <div className="header__main--left">
        <div className="header__main--left--logo">
          <img
            src={"https://studyfolksbucket.s3.ap-south-1.amazonaws.com/mv.png"}
            alt="logo"
            id="id--image"
          />
          {/* <h1>midnightVenture</h1> */}
        </div>
        <div className="header__main--left--content">
          <h1>
            The <em className="coffee">Coffee</em> break!
          </h1>
          <p>
            Keep the midnight lamp burning! until you reach your destination.
          </p>
        </div>
        <div className="header__main--left--form">
          <Form />
        </div>
      </div>
      <div className="header__main--right">
        <div className="header__main--right--container"></div>
      </div>
    </div>
  );
};

export default Header;
