import React from "react";
import "./offer.styles.scss";
import OfferCards from "./OfferCards";

const Offer = () => {
  return (
    <div className="offer__main">
      <div className="offer__main--content">
        <h1>What do offer?</h1>
        <div className="offer__main--content--cards">
          <OfferCards
            title={"One"}
            p1={"Newsletter evey week"}
            p2={"Directly in your Inbox"}
          />
          <OfferCards
            title={"Quick"}
            p1={"Byte sized content"}
            p2={"from the comfort of your home"}
          />
          <OfferCards
            title={"Easy"}
            p1={"To read and analyze"}
            p2={"Batteries included"}
          />
        </div>
      </div>
    </div>
  );
};

export default Offer;
