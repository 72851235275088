import "./App.scss";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Reviews from "./components/Reviews";
import Offer from "./components/Offer";
import Info from "./components/Info";
function App() {
  return (
    <section className="main">
      <Header />
      <Main />
      <Info />
      <Offer />
      {/* <Reviews /> */}
      <Footer />
    </section>
  );
}

export default App;
