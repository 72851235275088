import React from "react";
import "./slider.styles.scss";

const Slider = () => {
  const video = "https://studyfolksbucket.s3.ap-south-1.amazonaws.com/2024.mp4";
  return (
    <div className="slider__main">
      <video width="100%" height="100%" controls id="video">
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Slider;
