import React from "react";
import { useState } from "react";
import auth from "../utils.js";
import { createUserWithEmailAndPassword } from "firebase/auth";

const Form = () => {
  const [email, setEmail] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    setEmail("");

    try {
      // Create a new user with email and password
      const password = process.env.REACT_APP_FORM_FILL_PASSWORD;
      await createUserWithEmailAndPassword(auth, email, password);
      alert(
        "Welcome to community of top talents! A new world is waiting for you!"
      );
    } catch (error) {
      alert("either email is already registered or something happend!");
    }
  };

  return (
    <div className="form--div">
      <form action="" onSubmit={handleSignup}>
        <input
          type="email"
          id="email--form"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" id="sme">
          Sign me up!
        </button>
      </form>
    </div>
  );
};

export default Form;
